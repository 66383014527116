.content {
  max-width: rem(1376px);
  margin: auto;
  @media (min-width: $mantine-breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr;
    &[data-with-floater='true'] {
      grid-template-columns: 1fr rem(256px);
    }
  }
}

.preview {
  background-image: url('../../public/assets/images/preview.png');
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}